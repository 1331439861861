// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div style="width: 100%;">
    <!-- Delete Container Deployment -->
    <DeleteContainerDeployment
      v-if="showDeleteContainerModal"
      :showModal="showDeleteContainerModal"
      :deleteDeploymentId="containerId"
      :deleteDeploymentName="containerName"
      @close-action="closeAction"
      @set-loading="setLoading"
      @fetch-data="fetchData"/>
    <!-- Edit Container Deployment -->
    <EditContainerDeployment
      v-if="showEditContainerModal"
      :showModal="showEditContainerModal"
      :deployment="updateDeployment"
      :gateway="updateGateway"
      :accountUuid="updateDeploymentUuid"
      @close-action="closeAction"
      @set-loading="setLoading"
      @fetch-data="fetchData"/>
      <!-- create Kubernetes Deployment Modal -->
    <CreateContainerDeployment
      v-if="showCreateContainerModal"
      :fromContainerList="true"
      :showModal="showCreateContainerModal"
      :accountInfo="{ accUuid: loggedInAccount, accName: logginInAccountName }"
      :resellerSubAccounts="subAccounts"
      @close-action="closeAction"
      @set-loading="setLoading"
      @fetch-data="fetchData"/>
    <!-- Start of Table /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <a-card>
      <div style="display: flex; justify-content: flex-end; margin-bottom: 10px;">
        <a-tooltip class="row-action-button-console" arrowPointAtCenter placement="bottomRight" id="addEndpointBtn" @click="showCreateContainerModal=true">
          <template #title>
            {{ $t('label.container.deployment.create') }}
          </template>
          <span>
            <PlusOutlined />
          </span>
      </a-tooltip>
      </div>
      <a-table
        :columns="columns"
        :dataSource="items"
        :rowKey="(record, idx) => record.id || record.name || record.usageType || idx + '-' + Math.random()"
        :pagination="false"
        :loading="loading">
        <template #podsready="{ record }">
          <div v-if="record.podsready.split('\u0022')[1] === '0' || record.podsready.split('\u0022')[1]  === '' || record.podsready.split('\u0022')[1]  === undefined">
            <a-tooltip placement="bottom" :title="'(' + 0 + '/' + record.replicacount + 'Replikas)'">
              <status :text="'stopped'" />
            </a-tooltip>
          </div>
          <div v-else-if="record.podsready.split('\u0022')[1] < record.replicacount">
            <a-tooltip placement="bottom" :title="'(' + record.podsready.split('\u0022')[1] + '/' + record.replicacount + 'Replikas)'">
              <status :text="'stopped'" />
            </a-tooltip>
          </div>
          <div v-else>
            <a-tooltip placement="bottom" :title="'Running (' + record.podsready.split('\u0022')[1] + '/' + record.replicacount + 'Replikas)'">
              <status :text="'running'" />
            </a-tooltip>
          </div>
        </template>
        <template #name="{ record }">
          <img v-if="templateImageInfo.filter(x => x.file_name === record.info.logo)[0] === undefined" src="../../assets/icons/docker-logo-black.png" style="height: 15px; width: 20px;"/>
          <img v-else :src="'data:image/png;base64, ' + templateImageInfo.filter(x => x.file_name === record.info.logo)[0].Content" style="height: 20px; width: 20px;"/>
          <span style="margin-left: 5px;"> {{ record.name }}</span>
        </template>
        <template #accountName="{ record }">
          <router-link :to="{ path: '/account/' + record.accountUuid }">
            <span> {{ record.accountName }}</span>
          </router-link>
        </template>
        <template #networkid="{ record }">
          <router-link :to="{ path: '/guestnetwork/' + record.networkid }">
            <span> {{ networkData.filter(network => network.id === record.networkid)[0] ? networkData.filter(network => network.id === record.networkid)[0].displaytext : record.networkid }}</span>
          </router-link>
        </template>
        <template #licenses="{ record }">
          <a-tooltip v-if="record.licenses.length > 0 " placement="bottom" :title="record.licenses[0].licenseName">
            <span style="margin-right: 5px;"> {{ record.licenses[0].count }}</span><InfoCircleOutlined />
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template #options="{ record }">
          <TooltipButton
            tooltipPlacement="top"
            :tooltip="$t('label.container.deployment.edit')"
            style="margin-right: 10px;"
            icon="setting-outlined"
            type="default"
            @click="editDeployment(record)"/>&nbsp;
            <TooltipButton
            tooltipPlacement="top"
            :tooltip="$t('label.container.deployment.delete')"
            icon="delete-outlined"
            type="primary"
            danger
            @click="deleteDeployment(record)"/>
        </template>
      </a-table>
      <a-pagination
        class="row-element"
        style="margin-top: 10px"
        size="small"
        :current="page"
        :pageSize="pageSize"
        :total="items.length"
        :showTotal="total => `${$t('label.showing')} ${Math.min(total, 1+((page-1)*pageSize))}-${Math.min(page*pageSize, total)} ${$t('label.of')} ${total} ${$t('label.items')}`"
        @change="changePage"
        @showSizeChange="changePageSize"
        showSizeChanger
        showQuickJumper>
        <template #buildOptionText="props">
          <span>{{ props.value }} / {{ $t('label.page') }}</span>
        </template>
      </a-pagination>
    </a-card>
  </div>
</template>

<script>
import ListView from '@/components/view/ListView'
import TooltipButton from '@/components/widgets/TooltipButton'
import EditContainerDeployment from '@/views/compute/EditContainerDeployment.vue'
import CreateContainerDeployment from '@/views/compute/CreateContainerDeployment.vue'
import DeleteContainerDeployment from '@/views/compute/DeleteContainerDeployment.vue'
import Status from '@/components/widgets/Status'
import { api } from '@/api'
import wpapi from '@/wpApi/myApi'
import api2 from '@/wpApi/api2'

export default {
  name: 'containerDeploymentList',
  components: {
    ListView,
    TooltipButton,
    EditContainerDeployment,
    DeleteContainerDeployment,
    CreateContainerDeployment,
    Status
  },
  data () {
    return {
      networkData: [],
      showCreateContainerModal: false,
      showEditContainerModal: false,
      showDeleteContainerModal: false,
      updateDeployment: undefined,
      updateDeploymentUuid: undefined,
      updateGateway: undefined,
      loggedInAccount: this.$store.getters.userInfo.accountid,
      logginInAccountName: this.$store.getters.userInfo.account,
      loading: false,
      pageSize: 20,
      page: 1,
      currentPage: 1,
      items: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      subAccounts: [],
      templateImageInfo: [],
      columns: [
        {
          title: 'Status',
          dataIndex: 'podsready',
          slots: { customRender: 'podsready' },
          align: 'center'
        },
        {
          title: 'Name',
          dataIndex: 'name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'name'
          },
          sorter: function (a, b) { return a.name.localeCompare(b.name) },
          onFilter: (value, record) =>
            record.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              /* setTimeout(() => {
                document.getElementById('searchField').focus()
              }, 0) */
            }
          }
        },
        {
          title: 'Template',
          dataIndex: 'deploymentpath',
          slots: { customRender: 'deploymentpath' }
        },
        {
          title: this.$t('label.ipaddress'),
          dataIndex: 'ipaddress'
          // sorter: function (a, b) { return new Date(a.created_at) - new Date(a.created_at) }
        },
        {
          title: this.$t('label.account'),
          dataIndex: 'accountName',
          slots: { customRender: 'accountName' }
        },
        {
          title: this.$t('label.network'),
          dataIndex: 'networkid',
          slots: { customRender: 'networkid' }
        },
        {
          title: 'Replicas',
          dataIndex: 'replicacount'
        },
        {
          title: 'CPUs',
          dataIndex: 'cpuLimit'
        },
        {
          title: 'RAM',
          dataIndex: 'memLimit'
        },
        {
          title: this.$t('label.disk.size'),
          dataIndex: 'disksize'
        },
        {
          title: this.$t('label.licences'),
          dataIndex: 'licenses',
          slots: { customRender: 'licenses' }
        },
        {
          title: this.$t('label.settings'),
          dataIndex: 'options',
          slots: { customRender: 'options' }
        }
      ]
    }
  },
  provide: function () {
    return {
      parentFetchData: this.fetchData,
      parentToggleLoading: () => { this.loading = !this.loading }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.fetchNetworks()
      await this.getK8sTemplateImages()
      await this.fetchDeployments()
    },
    async fetchNetworks () {
      this.loading = true
      await api('listNetworks', { listall: true })
        .then(json => {
          this.networkData = json.listnetworksresponse.network
          if (!this.networkData || this.networkData.length === 0) {
            this.networkData = []
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.log(error)
        })
    },
    async getK8sTemplateImages () {
      this.loading = true
      await api2.getK8sTemplateImages().then(response => {
        this.templateImageInfo = response.data
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    async fetchDeployments () {
      this.loading = true
      var params = new FormData()
      params.append('accountuuid', this.loggedInAccount)
      await api2.getK8sDeploymentList(params).then(response => {
        var deployments = response.data.deployments
        for (var deployment of deployments) {
          deployment.accountUuid = this.loggedInAccount
          deployment.accountName = this.logginInAccountName
        }
        this.items = response.data.deployments === null ? [] : deployments
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
      if (this.$store.getters.userInfo.rolename === 'ResellerAdmin') {
        this.subAccounts = await this.getResellerSubAccounts()
        // call getK8sDeploymentList for each subAccount and add the deployments to the items list
        for (var subaccount of this.subAccounts) {
          var deploymentParams = new FormData()
          deploymentParams.append('accountuuid', subaccount.uuid)
          await api2.getK8sDeploymentList(deploymentParams).then(response => {
            var deployments = response.data.deployments
            if (deployments !== null) {
              for (var deployment of deployments) {
                deployment.accountUuid = subaccount.uuid
                deployment.accountName = subaccount.accountName
              }
              this.items.push(...deployments)
              this.loading = false
            }
            this.loading = false
          }).catch(error => {
            this.loading = false
            console.log(error)
          })
        }
      }
    },
    async fetchNetworkGateway (networkid) {
      await api('listNetworks', { id: networkid, listall: true }).then(json => {
        this.updateGateway = json.listnetworksresponse.network[0].gateway
        if (!json.listnetworksresponse.network || json.listnetworksresponse.network.length === 0) {
          this.updateGateway = undefined
        }
      }).catch(error => {
        this.$notification.error({
          message: this.$t('message.error.update.container'),
          description: error
        })
      })
    },
    deleteDeployment (deployment) {
      this.containerId = deployment.deploymentid
      this.containerName = deployment.name
      this.showDeleteContainerModal = true
    },
    async editDeployment (deployment) {
      await this.fetchNetworkGateway(deployment.networkid)
      this.updateDeployment = deployment
      this.updateDeploymentUuid = deployment.accountUuid
      this.showEditContainerModal = true
    },
    closeAction () {
      this.showCreateContainerModal = false
      this.showDeleteContainerModal = false
      this.showEditContainerModal = false
    },
    changePage (page, pageSize) {
      this.page = page
      this.pageSize = pageSize
    },
    changePageSize (currentPage, pageSize) {
      this.page = currentPage
      this.pageSize = pageSize
    },
    async getResellerSubAccounts () {
      var subAccounts = []
      await wpapi.getResellerSubAccounts(this.loggedInAccount).then(response => {
        subAccounts = response.data.filter(x => x.state === 'enabled' && x.removed === null) || 'test'
      }).catch(error => {
        console.log(error)
        subAccounts = []
      })
      return subAccounts
    },
    setLoading (loadBoolean) {
      this.loading = loadBoolean
    }
  }
}
</script>
<style scoped>
.row-action-button-console {
  font-size: 23px;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 5px;
  text-align: center;
  width: 32px;
  height: 32px;
  display: inline-block;
  margin-left: 8px;
  color: #fff;
}
/* .row-action-button-console {
  .ant-btn.ant-btn-dashed.ant-btn-circle {
    border: none;
    background: transparent;
    margin: 0 !important;
    padding: 0;
    color: #fff;
    font-size: 21px;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
} */
</style>
